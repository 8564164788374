export const PATH_ADD_CONTACT = 'add'
export const PATH_ADD_GROUP = 'add-group'
export const PATH_EDIT = 'edit'
export const PATH_PRESENTATION = 'presentation'
export const PATH_CONTACT = 'contact-infos'
export const PATH_ROLES = 'roles'
export const PATH_SERVICES = 'services'
export const PATH_SECURITY = 'security'

export default {
  PATH_ADD_CONTACT,
  PATH_ADD_GROUP,
  PATH_PRESENTATION,
  PATH_CONTACT,
  PATH_ROLES,
  PATH_SERVICES,
  PATH_SECURITY
}
