import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useStorage } from '@vueuse/core'
import useAPI from '@/composables/useApi'

const STORAGE_KEY = 'NAZ_TOKEN'
const STORAGE_ME_KEY = 'NAZ_ME'

export const useAuthentificationStore = defineStore('useAuthentificationStore', () => {
  const token = useStorage(STORAGE_KEY, '')
  const userInfos = useStorage(STORAGE_ME_KEY, {})
  const API = useAPI()

  const isLogged = computed(() => {
    return token.value !== ''
  })

  const isAdmin = computed(() => {
    return (userInfos.value.login) ? userInfos.value.login.includes('ZBS') : false
  })

  const isContact = computed(() => {
    return (userInfos.value.login) ? userInfos.value.login.includes('CBS') : false
  })

  const login = async (username, password) => {
    const response = await API.fetch('/login', {
      method: 'POST',
      data: {
        username,
        password
      },
      version: ''
    })
    if (response.data.token) {
      setToken(response.data.token)
      fetchUser()
    }
    return response
  }

  const fetchUser = async () => {
    const response = await API.fetch('/me', { method: 'get' })
    userInfos.value = response.data
    return response
  }

  const setToken = (value) => {
    token.value = value
    localStorage.setItem(STORAGE_KEY, token)
  }

  const disconnect = () => {
    token.value = ''
    userInfos.value = {}
    localStorage.removeItem(STORAGE_KEY)
  }

  return {
    token,
    userInfos,
    isLogged,
    isAdmin,
    isContact,
    login,
    setToken,
    disconnect
  }
})
